.header-first-section {
    min-height: 70vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.301), rgba(0, 0, 0, 0.609)),
        url(https://performance.goiania.br/api/files/stock_lecture_from_behind);
}

/* .header-site {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.301), rgba(0, 0, 0, 0.609)),
        url(https://performance.goiania.br/files/public/images/stock_lecture_from_behind.jpeg);
    align-items: center;
    justify-content: center;
} */


/* #info-empresa {
    padding: 50px 20px;
}

#info-empresa .title {

    margin-bottom: 3rem;
    font-size: 35px;
    text-align: center;
    font-weight: bold;
}

#info-empresa .about-subtitle {
    text-align: center;
    margin-left: 1rem;
    font-size: 23px;
} 

<div id="about">
	<div className="container" id="info-empresa">
		<div className="row bg-gray-200 p-8 rounded-md">
			<div className="col-12">
				<h3 className="title text-sky-600">Sobre nossa empresa</h3>
			</div>
			<div className="col-md-6">
				<img className="img-fluid" src="image/img3.jpg" alt="" />
			</div>
			<div className="col-md-6">
				<p className="about-subtitle mt-3 tracking-wide">
					Na Performance oferecemos cursos preparatórios para as Certificações RPPS, abrangendo todos os níveis, desde o básico até o avançado.
					Estamos constantemente desenvolvendo novos programas e cursos para ajudar gestores a atender às necessidade de seus colaboradores e servidores com o conhecimento técnico necessário para cada cargo dentro do RPPS. Se você busca a excelência na formação de profissionais estamos aqui para ajudá-lo a alcançar seus objetivos e fortalecer suas habilidades.
				</p>
				<p className="text-2xl text-center font-bold text-sky-500">VENHA ESTUDAR CONOSCO!!</p>
			</div>
		</div>
	</div>
</div>

*/